import { Component, Inject, OnInit } from "@angular/core";
import { User } from "../../models/user";
import { UserService } from "../../services/user/user.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: 'app-change-coop-dialog',
  templateUrl: './change-coop-dialog.component.html',
  styleUrls: ['./change-coop-dialog.component.scss']
})
export class ChangeCoopDialogComponent implements OnInit {

  user: User;
  selectedCoop: any;
  allCoops: any;

  constructor(
    public dialogRef: MatDialogRef<ChangeCoopDialogComponent>,
    private userService: UserService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.loadUser();
    this.loadData();
  }

  async loadUser(){
    this.user = this.userService.getUser();
  }
  //
  async loadData(){
    this.allCoops = this.user.allCoops.map((coop) => ({
      value: coop.code,
      display: coop.code + " - " + coop.name,
      coop,
    }))
  }

  coopSelected(options: any){
    this.selectedCoop = options;
  }

  changeCoopAction(){
    var updateCoop = this.user.allCoops.filter((coop) => coop.code == this.selectedCoop);
    
    this.user.locations = [];
    this.user.locations = updateCoop;
    this.userService.storageUser(this.user);
    this.refreshPage();
  }

  refreshPage(){
    const url = this.router.url;
    
    if(url.includes("/portal")){
      window.location.href = "/portal"
    }

    if(url.includes("/pac/crm")){
      window.location.href = "/pac/crm"
    }

    if(url.includes("/pac/marketing")){
      window.location.href = "/pac/marketing"
    }
  }
}